$phone-header: 598px;

.header {
  background-color: #000;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;

  &__nav {
  }

  &__logo {
    max-width: 87px;
    align-self: center;
    margin-right: 97px;
  }

  &__container {
    margin:0 auto;
    padding: 0 68px 0 58px;
    min-height: 60px;
    height: 80px;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;

    @include media($phone-header) {
      padding: 0 20px 0 30px;
    }
  }
}
