.point {
  padding-bottom: 70px;

  &__item {
    position: relative;
    padding-left: 40px;
    margin-bottom: 30px;

    @include phone {
      padding-left: 0;
      font-size: 14px;
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 9px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $pink;

      @include phone {
        width: 9px;
        height: 9px;
        top: 6px;
      }

      @include phone {
        display: none;
      }
    }

    &--yellow {
      &::before {
        background-color: $yellow;
      }
    }

    &--blue {
      &::before {
        background-color: $blue;
      }
    }
  }

  &__decor {

    display: none;

    @include media(510px) {
      display: block;
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-top: 36px;
      margin-bottom: -79px;
    }
  }

  &__decor-pic {
    width: 100%;
  }

  &__subpoint-list {
    margin-top: 30px;
  }
}
