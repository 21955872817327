.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.fixed {
  overflow: hidden;
}

.modal {
  background-color: #ffffff;
  max-width: 830px;
  min-height: 215px;
  position: relative;
  z-index: 11;
  margin: 0 auto;
  top: 12%;
  padding: 50px 40px;
  overflow: hidden;

  @include tablets {
    max-width: 500px;
    min-height: 364px;
    padding: 40px 30px;
  }

  @include phone {
    width: 96%;
    height: 91%;
    top: 3%;
  }


  &__container {
    height: 100%;
    width: 100%;

    @include phone {
      overflow-y: scroll;
    }

  }

  &__heading {

    @include phone {
      padding-left: 0;
      margin-bottom: 10px;
    }

  }

  &__share {
    margin-bottom: 20px;

    @include phone {
      display: block;
      text-align: center;

      .sharing__text {
        margin-right: 0;
      }

      .sharing__socials-list {
        justify-content: center;
      }
    }
  }

  &__link {
    color: $blue;
    text-decoration: underline;
    padding: 0 5px;
    overflow-wrap: break-word;
  }

  &__text {
    padding-bottom: 29px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    margin-bottom: 30px;

    @include phone {
      padding-bottom: 9px;
      margin-bottom: 10px;
    }
  }

  &__more-answers {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;

    @include phone {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  &__info {
    margin-bottom: 30px;
  }

  &__more-letter {
    color: $blue;
  }

  &__together {
    @include phone {
      .modal__more-answers {
        text-align: center;
        display: block;
      }
    }
  }

  &__logo {
    vertical-align: middle;
    margin-right: 22px;

    @include phone {
      display: block;
      text-align: center;
    }

    &_cn {
      .hero__logo_img {
        //width: 170px;

        @include phone {
          //width: 117px;
          margin-top: 11px;
          margin-bottom: 17px;
          vertical-align: middle;
        }
      }
    }

    &_marg-l {
      margin-left: 30px;
    }
  }


  &__close-button {

      position: absolute;
      right: 24px;
      top: 24px;
      width: 24px;
      height: 24px;
      opacity: 0.3;
    transition: 0.3s ease-out;

    &:hover {
      opacity: 1;
    }
    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 25px;
      width: 4px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
