.test {

  &__content {
    padding: 78px 0 70px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: calc(100% + 107px);
      top: 0;
      width: 1000px;
      height: 39%;
      background-image: url("../img/rectangle.svg");
      background-position: 32px 22px,41px;
      background-origin: padding-box;
      background-clip: border-box;
      background-size: 39px;
    }
  }

  &__subheading {
    position: relative;

    @include phone {
      padding-left: 45px;
    }

    &:before {
      content: url("../img/exclamation-mark.svg");
      position: absolute;
      left: -96px;
      transform: translateY(-50%);
      top: 50%;

      @include phone {
        top: 29px;
        left: -4px;
        transform: translateY(-50%) scale(0.5);
      }
    }
  }

  &__next-btn {
    padding: 15px 54px;
    cursor: pointer;
    outline: none;
    background-color: #000000;
    border: none;
    @include lubalin;
    font-size: 24px;
    color: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
  }

}
