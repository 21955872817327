.sharing {
  display: flex;
  align-items: center;

  &__text {
    margin-right: 23px;
  }

  &__socials-list {
    display: flex;
  }
}
