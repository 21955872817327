.quiz {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;

  display: none;

   &[data-js-step="1"] {
     display: block;
   }


  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 41px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    //color: #F87DA9;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid $pink;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $pink;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }



  &__question {
    background-color: $blue;
    padding: 40px 40px 40px 69px;
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;

    @include phone {
      font-size: 16px;
      padding: 20px;
    }
  }

  &__answer-field {
    border: 6px solid $blue;
    padding: 72px 40px 72px 22px;
    position: relative;


    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: -18px;
      width: 22%;
      height: 78px;
      background-image: url("../img/cross.svg");
      background-color: transparent;
      background-position: 21px 22px,41px;
      background-origin: padding-box;
      background-clip: border-box;
      background-size: 36px;

      @include phone {
        display: none;
      }
    }

    @include phone {
      padding: 30px 20px 72px 12px;
    }
  }

  &__decor {
    position: absolute;
    right: 0;
    bottom: -4px;
    overflow: hidden;

    @include media(425px) {
      text-align: right;
      right: -80px;
    }
  }

  &__decor-left {
    vertical-align: top;
    margin-right: 25px;

    @include media(338px) {
      margin-right: 86px;
    }
  }

  &__answer {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;

    @include phone {
      font-size: 14px;
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

}
