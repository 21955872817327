.subheading {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 30px;

  @include media(955px)
  {
    font-size: 26px;
  }

  @include phone {
    font-size: 18px;
  }
}
