@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url('../fonts/RoundedMplus1c-Regular.eot');
  src: url('../fonts/RoundedMplus1c-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RoundedMplus1c-Regular.woff2') format('woff2'),
  url('../fonts/RoundedMplus1c-Regular.woff') format('woff'),
  url('../fonts/RoundedMplus1c-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url('../fonts/RoundedMplus1c-Medium.eot');
  src: url('../fonts/RoundedMplus1c-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RoundedMplus1c-Medium.woff2') format('woff2'),
  url('../fonts/RoundedMplus1c-Medium.woff') format('woff'),
  url('../fonts/RoundedMplus1c-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rounded Mplus 1c';
  src: url('../fonts/RoundedMplus1c-ExtraBold.eot');
  src: url('../fonts/RoundedMplus1c-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RoundedMplus1c-ExtraBold.woff2') format('woff2'),
  url('../fonts/RoundedMplus1c-ExtraBold.woff') format('woff'),
  url('../fonts/RoundedMplus1c-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Lubalin Graph ITC Turner';
  src: url('../fonts/LubalinGraphITCTurner-Bold.eot');
  src: url('../fonts/LubalinGraphITCTurner-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/LubalinGraphITCTurner-Bold.woff2') format('woff2'),
  url('../fonts/LubalinGraphITCTurner-Bold.woff') format('woff'),
  url('../fonts/LubalinGraphITCTurner-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
