//$tablet-header: 1257px;

.socials-footer {

  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 50px;
    width: 1px;
    background-color: rgba(255,255,255,0.15);
  }

  &--no-before {
    &:before {
      width: 0;
    }
  }

  &__list {
    display: flex;
    padding-left: 70px;


    @include phone {
      padding-left: 0;
    }

  }

  &__item {
    margin-right: 54px;

    @include phone {
      margin-right: 28px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__pic {
    vertical-align: middle;
    fill: #ffffff;
    max-width: 25px;
    max-height: 20px;
    transition: 0.5s ease-out;

    &:hover {
      fill: $yellow;
    }

    #vk {
      width: 25px;
      vertical-align: bottom;
    }

    #insta, #fb {
      width: 20px;
    }
  }
}
