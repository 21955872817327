%clearfix {
  &:after, &:before {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}


@mixin retina {
	@media screen and (-webkit-mon-device-pixel-ratio:1.5), screen and (min-resolution: 144dpi){
		@content;
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder { @content; }
	&:-moz-placeholder           { @content; }
	&::-moz-placeholder          { @content; }
	&:-ms-input-placeholder      { @content; }
}

@mixin centered($axis: 'both'){
	position: absolute;

	@if $axis == 'both'{
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@if $axis == 'x'{
		left: 50%;
		transform:translateX(-50%);
	}

	@if $axis == 'y'{
		top: 50%;
		transform:translateY(-50%);
	}
}

@mixin media($width) {
	@media screen and (max-width: $width) {
		@content;
	}
}

@mixin desktop {
	@media screen and (max-width:1200px){
		@content;
	}
}

@mixin laptop {
	@media screen and (max-width:992px){
		@content;
	}
}

@mixin tablets {
	@media screen and (max-width:768px){
		@content;
	}
}

@mixin phone {
	@media screen and (max-width:800px){
		@content;
	}
}

@mixin autofill {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		@content;
	}
}


@mixin roundedMplus {
	font-family: 'Rounded Mplus 1c', 'Helvetica', sans-serif;
}

@mixin lubalin {
	font-family: 'Lubalin Graph ITC Turner';
	font-weight: bold;
	text-transform: uppercase;
}

//colors
$yellow: #FFEA00;
$pink: #E7429B;
$blue: #009BDF;
