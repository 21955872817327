.hero {
  background-image: url("../img/hero.jpg");
  min-height: 600px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  margin-top: 80px;
  color: #ffffff;
  padding-top: 140px;
  padding-bottom: 40px;

  @include media(1180px) {
    padding-left: 50px;
    padding-right: 50px;
  }


  @include phone {
    padding-top: 59px;
    padding-left: 0;
    padding-right: 0;
    min-height: 500px;
    background-position: 87% 0;
  }

  &__container {
    max-width: 1070px;
    margin: 0 auto;

    @include phone {
      padding: 0 15px;
    }
  }

  &__heading {
    font-size: 70px;

    @include media(1180px) {
      font-size: 50px;
    }

    @include phone {
      padding-left: 0;
    }
  }

  &__subheading {

    @include media(1180px){
      font-size: 27px;
    }

    @include phone {
      margin-bottom: 10px;
    }
  }

  &__text {
    font-size: 18px;
    display: inline;
    margin-right: 13px;

    @include phone {
      font-size: 16px;
    }
  }

  &__logo {
    vertical-align: middle;

    &_cn {
      .hero__logo_img {
        width: 220px;

        @include media(858px) {
          width: 117px;
          margin-top: 11px;
          margin-bottom: 17px;
          vertical-align: middle;
        }
      }
    }

    &_marg-l {
      margin-left: 30px;
    }
  }

  &__logo_img {

  }

  &__logos {
    margin-bottom: 74px;

    @include phone {
      margin-bottom: 18px;
    }
  }
}
