.intro {
  position: relative;

  &__text {
    position: relative;
    padding: 70px 0 76px;

    @include phone {
      max-width: 78%;
      margin: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: calc(100% + 107px);
      top: 0;
      width: 1000px;
      height: 100%;
      background-color: $yellow;
      background-image: url("../img/cross.svg");
      background-position: 26px 22px,41px;
      background-origin: padding-box;
      background-clip: border-box;
      background-size: 41px;

      @include phone {
        left: calc(100% + 16px);
      }
    }
  }


  &__p {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

}
