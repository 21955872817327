$tablet-header: 1257px;
$phone-header: 424px;

.nav {
  display: flex;

  &__list {
    display: flex;

    @include media($tablet-header) {
      position: absolute;
      opacity: 0;
      top: 80px;
      right: 0;
      pointer-events: none;
      padding: 30px 58px;
      flex-wrap: wrap;
      width: 100%;
      transition: 0.5s;
      justify-content: center;

      &.active {
        opacity: 1;
        pointer-events: auto;
        background-color: #000000;

        @include media($phone-header) {
          background-color: rgba(0,0,0,0.9);
        }
      }
    }

    @include media(926px) {
      justify-content: initial;
    }

    @include media($phone-header) {
      display: block;
      padding: 30px;
    }
  }

  &__item {
    margin-right: 68px;
    border-bottom: 6px solid transparent;
    transition: 0.5s ease-out;
    display: flex;
    align-items: center;

    &--fake-sect {
      width: 0;
      height: 0;
    }

    @include media($tablet-header) {
      &:nth-child(6) {
        margin-right: 0;
      }
    }


    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-bottom-color: $yellow;

      @include media($tablet-header) {
        border-bottom-color: transparent;
      }
    }

  }

  &__link {
    @include lubalin;
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    transition: 0.5s ease-out;

    &:hover {
      color: $yellow;
    }

    @include media($tablet-header) {
      padding-bottom: 10px;
    }

  }

  &__mobile-menu {

    display: none;
    cursor: pointer;

    @include media($tablet-header) {
      display: inherit;
      background-color: transparent;
      border: none;
      outline: none;
      position: relative;
      z-index: 11;

      &.hidden {
        display: none;
      }
    }
  }
}

.section {
  height: 400px;
  border: 1px solid red;
}

.first {
  height: 600px;
  background-color: lightcoral;
}
.second {
  height: 500px;
  background-color: #5959f0;
}
.third {
  height: 800px;
  background-color: #80f09c;
}
.fourth {
  height: 500px;
  background-color: #5959f0;
}
.fifth {
  height: 900px;
  background-color: #23456e;
}
