.bullying {

  background-color: $pink;
  color: white;
  position: relative;


  &:after {
    content: url("../img/bullying-left-corner.svg");
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: -4px;

    @include phone {
      display: none;
    }
  }

  &__content {
    padding: 77px 0 70px;
    position: relative;

    @include phone {
      padding-bottom: 109px;
    }

    &:after {
      content: '';
      position: absolute;
      left: calc(100% + 107px);
      top: 0;
      width: 1000px;
      height: 100%;
      background-image: url("../img/cross-white.svg");
      background-position: 26px 22px,41px;
      background-origin: padding-box;
      background-clip: border-box;
      background-size: 41px;

      @include phone {
        left: -8px;
        top: calc(100% - 94px);
      }
    }
  }
}
