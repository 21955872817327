$tablet-header: 1257px;

.mobile-menu {
  display: none;
  height: 20px;


  @include media($tablet-header) {
    display: block;
    position: relative;
    width: 34px;
  }
  &__line {
    position: absolute;
    width: 22px;
    height: 2px;
    left: 0;
    top: 0;
    background: white;
    border-radius: 2px;
    transition: 0.5s;

    &_top {

    }
    &_middle {
      top: 8px;
    }
    &_bottom {
      top: 16px;
    }
  }

  &.active {
    .mobile-menu__line {
      transition: 0.5s;

      &_top {
        transform: rotate(45deg);
        top: 9px;
      }
      &_middle {
        opacity: 0;

      }
      &_bottom {
        transform: rotate(135deg);
        top: 9px;

      }
    }
  }
}
