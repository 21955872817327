.accordion {
  &__sliding-part {
    display: none;

    &.container {
      @include phone {
        padding-top: 0;
        padding-bottom: 0;
      }

      .point__item {
        padding-left: 19px;
      }
    }
  }

  &__action {
    cursor: pointer;
  }
}
