.heading {
  font-size: 40px;
  @include lubalin;
  margin-bottom: 20px;
  position: relative;

  @include media(955px) {
    font-size: 36px;
  }

  @include phone {
    font-size: 20px;
    padding-left: 49px;
}

  &:before {
    content: '';
    position: absolute;
    left: -96px;
    top: -4px;


    @include phone {
      transform: scale(.5);
      left: -15px;
      top: -20px;
    }

  }

  &--question {
    &:before {
      content: url("../img/question.svg");
    }
  }

  &--star {
    &:before {
      content: url("../img/star.svg");
    }
  }

  &--lightning {
    &:before {
      content: url("../img/lightning.svg");
    }
  }

  &--tick {
    &:before {
      content: url("../img/tick.svg");
    }
  }

  &--centered {
    &:before {
      top: 15px;
    }
  }

}
