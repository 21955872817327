$tablet-header: 1257px;

.socials {

  position: relative;

  @include media($tablet-header) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &:before {
    content: '';
    position: absolute;
    height: 50px;
    width: 1px;
    background-color: rgba(255,255,255,0.15);

    @include media($tablet-header) {
      height: 1px;
      top: 0;
      width: 100%;
    }
  }

  &--no-before {
    &:before {
      width: 0;
    }
  }

  &__list {
    display: flex;
    padding-left: 70px;

    @include media($tablet-header) {
      padding-left: 0;
      padding-top: 30px;
      align-self: center;
    }
  }

  &__item {
    margin-right: 54px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__pic {
    vertical-align: middle;
    fill: #ffffff;
    max-width: 25px;
    max-height: 20px;
    transition: 0.5s ease-out;

    &_marg {
      margin-right: 23px;

      @include phone {
        margin-right: 14px;
      }

    }

    &:hover {
      fill: $yellow;
    }

    #vk {
      width: 25px;
      vertical-align: bottom;
    }

    #insta, #fb {
      width: 20px;
    }

    &_blue {
      fill: $blue;
    }
  }
}
