.vs {
  background-color: $blue;
  color: #ffffff;
  position: relative;

  &:before {
    content: url("../img/vs-top-squares.svg");
    position: absolute;
    right: 0;
    top: 0;

    @include phone {
      display: none;
    }
  }

  &:after {
    content: url("../img/vs-squares.svg");
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: -4px;

    @include phone {
      display: none;
    }
  }

  &__content {
    padding: 78px 0 0;

    @include phone {
      padding-bottom: 0;
    }
  }

  &__subheading {
    margin-bottom: 40px;
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    position: relative;

    @include phone {
      .heading {
        padding-left: 0;
      }
    }

    &:after {
      @include phone {
        content: url("../img/vs-col-small.svg");
        position: absolute;
        z-index: 3;
        right: -15px;
        top: -40px;
      }
    }
  }

  &__left-col {
    width: 50%;
    padding-right: 87px;
    padding-top: 50px;

    @include phone {
      padding-right: 12px;
      padding-top: 20px;
    }
  }

  &__right-col {
    width: 50%;
    position: relative;
    z-index: 3;
    color: #000;
    padding-top: 50px;
    padding-left: 32px;

    @include phone {
      padding-left: 12px;
      padding-top: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      background-color: white;
      width: 50vw;
      height: 100%;
      left: 0;
      top: 0;

    }
  }

  &__list {
    margin-top: 40px;
  }
}
