.comparison {
  background-color: $pink;
  color: #ffffff;
  position: relative;

  @include media(955px) {
    &:before {
      display: none;
    }
  }

  &:before {
    content: url("../img/comparison-squares.svg");
    position: absolute;
    left: 0;
    top: 0;
  }


  &__content {
    padding: 78px 0 70px;
    position: relative;
  }

  &__ticks {
    padding-bottom: 40px;
    margin-left: 48px;
  }

  &__tick-item {
    position: relative;
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0;
    }

    &:before {
      content: url("../img/check.svg");
      position: absolute;
      left: -47px;
      top: -2px;
    }

    @include phone {
      &:before {
        transform: scale(0.7);
      }
    }
  }

  &__exclamation {
    background-color: #ffffff;
    padding: 45px 14px 45px 104px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);

    @include media(1120px) {
      padding-right: 28px;
    }

    @include phone {
      padding: 20px 20px 20px 56px;
    }
  }

  &__excl-text {
    color: #000;
    font-size: 24px;
    font-weight: 800;
    position: relative;

    @include phone {
      font-size: 16px;
    }

    &:before {
      content: url("../img/exclamation-mark.svg");
      position: absolute;
      left: -72px;
      top: 50%;
      transform:translateY(-50%) scale(0.6);

      @include phone {
        left: -52px;
      }
    }
  }
}
