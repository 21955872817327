.footer {
  background-color: #000000;

  &__container {
    overflow: hidden;
    max-width: 1440px;
    margin: 0 auto;
    padding: 40px 68px 40px 58px;

    @include phone {
      padding: 15px;
    }
  }

  &__logo {
    max-width: 87px;
    align-self: center;
    margin-right: 97px;

    @include phone {
      margin-right: 79px;
    }
  }


  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__bottom {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    position: relative;


    @include phone {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      height: 1px;
      top: 0;
      width: 100%;
      background-color: rgba(255,255,255,0.15);
    }
  }

  &__text-container {
    max-width: 870px;
    font-size: 12px;
  }

  &__copyright {
    font-size: 16px;
    margin-left: 50px;

    @include phone {
      margin-left: 0;
      margin-top: 15px;
    }

  }

  &__logo-link {
    color: #ffffff;
    text-decoration: underline;
    transition: 0.3s ease-out;

    &:hover {
      color: $yellow;
    }
  }
}
