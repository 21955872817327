.question {

  position: relative;

  &__decor {
    position: absolute;
    left: 0;
    bottom: -7px;
    display: none;
  }

  &__decor-top {
    position: absolute;
    top: 0;
    right: 0;

    @include media(860px) {
      display: none;
    }
  }
  &__decor-bot {
    display: none;
    position: absolute;
    bottom: -13px;
    right: 0;
  }


  &__trigger {
    transform: rotate(180deg);
    transition: 0.3s;

    svg {
      @include phone {
        width: 18px;
      }
    }

    &.active {
      transform: rotate(0deg);
    }
  }


  &--blue {
    background-color: $blue;
    color: #ffffff;
  }

  &__static {
    padding: 70px 0;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .heading {
      margin-bottom: 0;
    }
  }

  .point__item {
    &:before {
      display: initial;
    }
  }

}


//.bottom-squares {
//  &:after {
//    opacity: 0;
//  }
//}
