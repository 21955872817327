// @include add-grid(12);
// @include add-gutter(1/3);
// @include add-gutter-style('split');

// @include sgs-change('debug', true);

// .container {
//   @include background-grid($color: blue);
// }

//singylarity usage
//@include grid-span(6,1);  1 - сколько занять, 2 - откуда начать

html, body {
	width: 100%;
	height: 100%;
}

body {
	@include roundedMplus;
	font-size: 14px;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	line-height: 1.42;
}

* {
	box-sizing: border-box;
}

p {
	margin: 0;
	font-size: 20px;

	@include media(1120px) {
		font-size: 18px;
	}

	@include phone {
		font-size: 14px;
	}
}

.container {
	max-width: 870px;
	margin:0 auto;

	@include media(1120px) {
		max-width: 583px;
	}

	@include phone {
		padding: 30px 15px;
	}
}

.container--big {

}

.wrapper {
	position: relative;
	min-height: 100%;
	overflow: hidden;
}

a {
	text-decoration: none;
}

ul {
	padding: 0;
	margin: 0;
}

ul li {
	list-style: none;
	font-size: 20px;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: normal;
	line-height: 1.42;
}
